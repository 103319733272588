import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom"
import App from './App'
import {history} from "./helpers/history";
import LoginPage from "./pages/LoginPage"
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
    <HashRouter>

        <App />
    </HashRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
