import React from "react";
import {UsersService} from "../services/UsersService";
import withRouter from "../components/WithRouter";
import Loader from "../components/Loader";
import FormGroup from "../components/FormGroup";
import Select from "react-select";
import {DepartmentService} from "../services/DepartmentService";

class UserEditMoney extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cash: {
                rate: '',
                advance_cash: '',
                advance_bank: '',
                salary_cash: '',
                salary_bank: '',
                prize: ''
            },
            services: [],
            additionals: false,
            groups: false,
            loaded: false,
        }
        this.additionals_values_varient = {
            'single': '1 занятие',
            'salary': '1 месяц',
            'procent': '%'
        }

        this.handleAddAdditional = this.handleAddAdditional.bind(this)
        this.handleAddMainAdditional = this.handleAddMainAdditional.bind(this)
        this.handleAddIndividual = this.handleAddIndividual.bind(this)
    }


    handleAddAdditional(e) {
        this.setState({services: this.state.services.concat([{value: '', additional_id: '', group_id: '', type: 'additional'}])})
        console.log(this.state.services)
        e.preventDefault()
    }

    handleAddMainAdditional(e) {
        this.setState({services: this.state.services.concat([{value: '', additional_id: '', group_id: '', type: 'kindergarten'}])})
        console.log(this.state.services)
        e.preventDefault()
    }


    handleAddIndividual(e) {
        this.setState({services: this.state.services.concat([{value: '', additional_id: '', group_id: '', type: 'individual'}])})
        console.log(this.state.services)
        e.preventDefault()
    }


    componentDidMount() {
        let cleanupFunction = false;
        const fetchData = async () => {
            try {
                UsersService.GetCash(this.props.router.params.userId, localStorage.getItem('currentDepartment')).then((response) => {
                    if (response.items.length) {
                        this.setState({cash: response.items[0]})
                    }
                })
                UsersService.GetServices(this.props.router.params.userId, localStorage.getItem('currentDepartment')).then((response) => {
                    this.setState({services: response.items})
                })
                DepartmentService.getGroups({'filter': {'department_id': localStorage.getItem('currentDepartment')}}).then((response) => {
                    this.setState({groups: response.items})
                })
                DepartmentService.getAdditionals({'filter': {'department_id': localStorage.getItem('currentDepartment')}}).then((response) => {
                    this.setState({additionals: response.items, loaded: true})
                })
            } catch (e) {

            }
        }
        fetchData();

        // функция очистки useEffect
        return () => cleanupFunction = true;
    }

    render() {

        return (
            <>
                {this.state.loaded ?
                    <>
                        <form action="#" className={'theme-form'}>

                            <h4 className={'m-b-30'}>Основные</h4>
                            <FormGroup label={"Ставка"}>
                                <input type={'text'} className={'form-control'} placeholder={'Ставка '}
                                       value={this.state.cash.rate}/>
                            </FormGroup>

                            <div className="row">

                                <div className="col">
                                    <FormGroup label={'Аванс кеш'}>
                                        <input type={'text'} className={'form-control'} placeholder={'Аванс кеш '}
                                               value={this.state.cash.advance_cash}/>
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup label={'Аванс безнал'}>
                                        <input type={'text'} className={'form-control'} placeholder={'Аванс безнал '}
                                               value={this.state.cash.advance_bank}/>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <FormGroup label={'Зарплата кеш'}>
                                        <input type={'text'} className={'form-control'} placeholder={'Зарплата кеш '}
                                               value={this.state.cash.salary_cash}/>
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup label={'Зарплата безнал'}>
                                        <input type={'text'} className={'form-control'} placeholder={'Зарплата безнал '}
                                               value={this.state.cash.salary_bank}/>
                                    </FormGroup>
                                </div>
                            </div>

                            <button type={'submit'} className={'btn btn-primary'}>Сохранить</button>
                        </form>

                        <hr/>

                        <form className="theme-form">
                            <h4 className={'m-b-30'}>Доп. занятия основные</h4>

                            {this.state.services.map((el, k) => {

                                if(el.type === 'kindergarten') {

                                    return (
                                        <div className={'row m-b-10'}>
                                            <div className="col">
                                                <select value={el.group_id} className={'form-select'}>
                                                    {this.state.groups && this.state.groups.map((option, k) => {

                                                            return (
                                                                <option value={option.id}>{option.name}</option>
                                                            )

                                                    })}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <select value={el.additional_id} className={'form-select'}>
                                                    {this.state.additionals && this.state.additionals.map((option) => {
                                                        if(option.type === 'kindergarten') {
                                                            return (
                                                                <option value={option.id}>{option.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <input type="text" value={el.value} className={'form-control'}
                                                       placeholder={'Сумма'}/>
                                            </div>
                                            <div className="col">
                                                <select value={el.cash_type} className={'form-select'}>
                                                    {Object.entries(this.additionals_values_varient).map((option) => {
                                                        return (
                                                            <option value={option[0]}>{option[1]}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                            })}


                            <a href="#" className={'btn btn-light'} onClick={this.handleAddMainAdditional}>Добавить</a>


                            <hr/>
                            <h4 className={'m-b-30'}>Доп. занятия дополнительные</h4>


                            {this.state.services.map((el, k) => {

                                if(el.type === 'additional') {

                                    return (
                                        <div className={'row m-b-10'}>
                                            <div className="col">
                                                <select data-id={el.additional_id} value={el.additional_id} className={'form-select'}>
                                                    {this.state.additionals && this.state.additionals.map((option) => {
                                                        if(option.type === 'additional') {
                                                            return (
                                                                <option value={option.id}>{option.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <input type="text" value={el.value} className={'form-control'}
                                                       placeholder={'Сумма'}/>
                                            </div>
                                            <div className="col">
                                                <select value={el.cash_type} className={'form-select'}>
                                                    {Object.entries(this.additionals_values_varient).map((option) => {
                                                        return (
                                                            <option value={option[0]}>{option[1]}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                            <a href="#" className={'btn btn-light'} onClick={this.handleAddAdditional}>Добавить</a>
                            <hr/>
                            <h4 className={'m-b-30'}>Индивидуальные</h4>


                            {this.state.services.map((el, k) => {

                                if(el.type === 'individual') {

                                    return (
                                        <div className={'row m-b-10'}>
                                            <div className="col">
                                                <select value={el.additional_id} className={'form-select'}>
                                                    {this.state.additionals && this.state.additionals.map((option) => {
                                                        if(option.type === 'individual') {
                                                            return (
                                                                <option value={option.id}>{option.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <input type="text" value={el.value} className={'form-control'}
                                                       placeholder={'Сумма'}/>
                                            </div>
                                            <div className="col">
                                                <select value={el.cash_type} className={'form-select'}>
                                                    {Object.entries(this.additionals_values_varient).map((option) => {
                                                        return (
                                                            <option value={option[0]}>{option[1]}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                            <a href="#" className={'btn btn-light'} onClick={this.handleAddIndividual}>Добавить</a>

                            <div className="d-flex text-end m-t-30">
                                <button type={'submit'} className={'btn btn-primary m-r-10'} >Сохранить</button>

                            </div>


                        </form>
                    </>
                    : <Loader/>}
            </>
        )
    }


}

export default withRouter(UserEditMoney)