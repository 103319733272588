import React from "react";

export default function FormGroup(props){

    let icon = false
    if(props.icon){
        icon =  <span className="input-group-text">
            <i className={'icon icon-' + props.icon}>
            </i>
        </span>
    }

    return (
        <div className={'form-group'}>
            <label>{props.label}</label>
            <div className="input-group">

                {icon}
                {props.children}


            </div>

        </div>
    )
}