import Header from "./_header";
import Sidebar from "./_aside";
import {useSelector} from "react-redux";
import {userService} from "../services/UserService";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as HomeSvg} from "../assets/icons/home.svg";
import {ReactComponent as AwardSvg} from "../assets/icons/star.svg";
import {ReactComponent as BookSvg} from "../assets/icons/book.svg";
import {ReactComponent as ChatSvg} from "../assets/icons/message-circle.svg";
import {ReactComponent as MoneySvg} from "../assets/icons/dollar-sign.svg";
import {ReactComponent as BackSvg} from "../assets/icons/arrow-left.svg";
import React from 'react'
import Nav from "./_nav";
import {useLocation} from 'react-router-dom';

export default function CrmLayout(props) {
    const mainWrapper = useRef(null)
    const [BM, setBM] = useState(false);
    let navigation = useNavigate();
    const [curChild, setCurChild] = useState(userService.GetCurrentChild())

    const userData = useSelector(function (state) {
        return state.auth.userData

    });


    let location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


    const pageTitle = useSelector((state) => {
        return state.pageTitle
    })

    const breadcrumbs = useSelector((state) => {
        return state.breadcrumbs
    })


    const isLogout = useSelector(function (state) {
        return state.auth.logout
    });

    if (isLogout) {
        navigation('/login');
    }

    if (!userData) {
        userService.UpdateInfo()
    }

    function locationBack() {
        navigation(-1)
    }

    useEffect(() => {

        // console.log(location)
        if (userData && userService.GetCurrentChild()) {
            userData.children.map((child) => {
                if (child.id == userService.GetCurrentChild()) {
                    console.log('child', child)
                    setCurChild(child)
                }
            })

        }
        if (location) {
            mainWrapper.current.scrollTop = 0;
        }
    }, [userData, location])


    return (
        <div className={'page-wrapper compact-wrapper'} ref={mainWrapper}>
            {/*<Header/>*/}
            <header>

                {location && location.pathname !== '/about' &&
                    <div onClick={() => locationBack()} className={'header__back'}>
                        <BackSvg/>
                    </div>
                }

                <div className="page-title">

                    <h3>{pageTitle}</h3>
                </div>


                {location.pathname != '/about' && curChild &&
                    <Link to={'/about'} className={'header__childs'}>
                        <div className="header__child">
                            <img src={"https://crm.discoveryschool.ru" + curChild.avatar} alt=""/>
                        </div>
                    </Link>
                }
            </header>
            <div className="page-body-wrapper">


                <div className="pageBody">
                    <div className="container-fluid">


                        {props.children}

                        <Nav/>
                    </div>
                </div>
            </div>


        </div>
    )
}