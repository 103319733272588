import React from "react";
import {userService} from "../services/UserService";
import {useNavigate} from "react-router-dom";


export default function Exit(){

    const navigation = useNavigate();

    function logoutSubmit(){

        userService.Logout();
        navigation('/login');
    }

    setTimeout(logoutSubmit, 3000);
    logoutSubmit()

    return (
        <p>Выход из системы...</p>
    )
}