import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';

import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import axios from "axios";
import Loader from "../components/Loader";
import Contract from "../components/Contract";
import {userService} from "../services/UserService";

export default function Kindergarten(props) {

    const {contractId} = useParams()
    const dispatch = useDispatch()
    const [contract, setContract] = useState(null)
    const [contracts, setContracts] = useState([])
    const [finances, setFinances] = useState([])
    const [journal, setJournal] = useState([])
    const [count, setCount] = useState(null)
    const [debt, setDebt] = useState(0)
    const [balance, setBalance] = useState(0)
    const [date, setDate] = useState('')

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['пн', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        monthNamesShort: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        today: 'сегодня',
        clear: 'очистить'
    });

    locale('ru')

    dispatch(setTitle('Детский клуб'))
    dispatch(setBreadcrumbs('/'))


    useEffect(() => {
        if (userData) {
            console.log('ud', userData)
            setContracts(userData.contracts.filter(e => e.id == contractId))
        }
    }, [userData])

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.children && contracts.length > 0) {
                // console.log('cts', contracts[0].group_object.name);
                // dispatch(setTitle('Контракт #' + contracts[0].id + ' от ' + contracts[0].date_from))
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getReceipts?children_id=' + userService.GetCurrentChild() + '&contract_id=' + contractId,
                );
                setFinances(result.data);

                console.log('dddd', date)

                const result2 = await axios(
                    'https://crm.discoveryschool.ru/_api/getAttendance?children_id=' + userService.GetCurrentChild() + '&contract_id=' + contractId + "&date=" + (date !== false ? Date.parse(date) : ''),
                );
                setJournal(result2.data);

                let c = 0
                // userData.children[0].service_count.filter(e => e.additional_id == additionalId).map((el) => {
                //     c+= parseInt(el.count);
                // })
                setCount(c)

                if(c >= 0){
                    setBalance(c*contracts[0].price)
                }
                if(c < 0){
                    setDebt(c*contracts[0].price)
                }
            }
        }
        fetchData()
    }, [contracts, date])


    return (
        contracts && contracts.length > 0 ? <div className={'additional-page'}>

            <div className={'block'}>
                <h3 className={''}>Группа {contracts[0].group_object.name}</h3>


                {/*<h4 className={'teachers-title'}>Педагог</h4>*/}
                {contracts[0].group_object.teachers_list.map((teacher) => {
                    return <>

                        <div className="teacher">
                            <div className="teacher__avatar">
                                <img src={"https://crm.discoveryschool.ru" + teacher.avatar} alt=""/>
                            </div>
                            <div className="teacher__info">
                                <div className="teacher__name">{teacher.name}</div>
                                <div className="teacher__position">{teacher.position}</div>
                            </div>
                        </div>

                    </>
                })}

                {contracts.map((c) => {
                    return <Contract hideTitle={true} contract={c}/>
                })}


                {contracts.length === 0 &&
                    <>
                        <p><strong style={{fontSize: '1rem'}}>2 000 ₽</strong> - 1 занятие</p>
                        <button className={'btn btn-primary w-100'}>Оставить заявку</button>
                    </>
                }

                {balance > 0 && <div className={'balance'}>Баланс: {balance} ₽</div> }
                {debt < 0 && <div className={'debt'}>Задолженность: {debt} ₽</div> }





            </div>


                <>

                    <div className="block">
                        <h3 className={''}>Журнал посещаемости</h3>

                        <Calendar touchUI placeholder={'Выберите месяц и год'} value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="MM yy" />

                        {journal &&
                            <table className="table">
                                <tbody>
                                {journal.map((row, k) => {
                                    if (k < 20) {
                                        return (
                                            <tr key={'j' + row.id}>
                                                <td>{row.date_public}</td>
                                                <td className={'at-status_' + row.status}>{row.status_public}</td>
                                            </tr>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        }
                    </div>

                </>



            {finances.length > 0  && 1 == 2 ?
                <>

                    <div className="block">
                        <h3 className={''}>История платежей</h3>
                        {finances ? <table className={'table'}>
                            <tbody>
                            {finances.map((row) => {
                                    return (
                                        <tr key={'k' + row.id}>


                                            <td><small style={{padding: 0}}>{row.date_public}</small><br/>{row.category}
                                                <div dangerouslySetInnerHTML={{__html: row.table_comment}}
                                                     style={{padding: 0}}></div>
                                            </td>
                                            <td>
                                                <div style={{padding: 0}} className={'amount'}>

                                                    {row.amount_public}</div>
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table> : <Loader/>}

                    </div>
                </>
                : ''}


        </div> : <Loader/>
    )

}