import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import Loader from "../components/Loader";
import axios from "axios";
import Contract from "../components/Contract";
import {userService} from "../services/UserService";
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions} from 'primereact/api';


export default function Finances(props) {

    const dispatch = useDispatch()
    const [finances, setFinances] = useState([])
    const [date, setDate] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [type, setType] = useState('');

    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['пн', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        monthNamesShort: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        today: 'сегодня',
        clear: 'очистить'
    });

    locale('ru')

    dispatch(setTitle('Финансы'))
    dispatch(setBreadcrumbs([]))

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        setFinances(false)
        const fetchData = async () => {
            if (userData && userData.children) {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getReceipts?children_id=' + userService.GetCurrentChild() + '&date=' + (date !== false ? Date.parse(date) : '') + '&date_to=' + (dateTo !== false ? Date.parse(dateTo) : '') + '&type=' + type,
                );
                setFinances(result.data);
            }
        }
        fetchData()
    }, [userData, date, dateTo, type])

    return (
        <>
            {1 == 2 &&
                <div className={'finances block'}>
                    <h3 className={''}>Услуги</h3>
                    {userData ? <div className={'finances-contracts'}>
                        {userData.contracts.filter(el => el.child.id == userService.GetCurrentChild()).map((contract) => {

                                return (
                                    <Contract contract={contract}/>
                                )

                            }
                        )}


                    </div> : <Loader/>}
                </div>
            }
            <div className="block">
                <h3 className={''}>История операций</h3>

                <div className={'flex'}>

                    <div className="row">
                        <div className="col">
                            <Calendar touchUI placeholder={'С'} value={date} onChange={(e) => setDate(e.value)}
                                      view="month" dateFormat="MM yy"/>
                        </div>
                        <div className="col">
                            <Calendar touchUI placeholder={'По'} value={dateTo} onChange={(e) => setDateTo(e.value)}
                                      view="month" dateFormat="MM yy"/>
                        </div>
                    </div>
                    <Dropdown value={type} onChange={(e) => setType(e.value)} options={[
                        {
                            label: 'Все',
                            value: ''
                        },
                        {
                            label: 'Детский сад',
                            value: 'kindergarten'
                        },
                        {
                            label: 'Доп. занятия',
                            value: 'additional_class'
                        },
                        {
                            label: 'Индивидуальные занятия',
                            value: 'individual_lesson'
                        }
                    ]} optionLabel="label"
                              placeholder="Тип платежей" className="w-full"/>

                </div>

                {finances ? <table className={'table'}>
                    <thead>
                    {/*<tr>*/}
                    {/*    <th>Категория</th>*/}
                    {/*    <th>Cумма</th>*/}
                    {/*</tr>*/}
                    </thead>
                    {finances.map((row) => {
                            return (
                                <tr>


                                    <td><small style={{padding: 0}}>{row.date_public}</small><br/>{row.category}
                                        <div dangerouslySetInnerHTML={{__html: row.table_comment}}
                                             style={{padding: 0}}></div>
                                    </td>
                                    <td>
                                        <div style={{padding: 0}} className={'amount'}>

                                            {row.amount_public}</div>
                                    </td>
                                </tr>
                            )
                        }
                    )}
                </table> : <Loader/>}

            </div>
        </>
    )
}