import React, {useEffect, useState} from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import axios from "axios";
import Loader from "../components/Loader";
import {userService} from "../services/UserService";
import {Calendar} from 'primereact/calendar';
import {addLocale, locale} from "primereact/api";

export default function Timeline(props) {

    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['пн', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        monthNamesShort: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        today: 'сегодня',
        clear: 'очистить'
    });

    locale('ru')

    const dispatch = useDispatch()

    dispatch(setTitle('Расписание'))
    dispatch(setBreadcrumbs([]))

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    const [timeLine, setTimeline] = useState(false)
    const [activeGroup, setActiveGroup] = useState(false)
    const [date, setDate] = useState(false)

    useEffect(() => {
        if(userData) {
            userData.children.map((el) => {
                if (el.id == userService.GetCurrentChild()) {
                    console.log('ag', el.active_group_id)
                    setActiveGroup(el.active_group_id);
                }
            })
        }
        if(activeGroup) {
            const fetchData = async () => {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getTimeline?group_id=' + activeGroup + '&date=' + (date !== false ? Date.parse(date) : '')
                );

                setTimeline(result.data);
            }
            fetchData()
        }
    }, [userData, activeGroup, date])



    return (
        <div className={'block'}>

            <Calendar touchUI placeholder={'Сегодня'} value={date} onChange={(e) => setDate(e.value)}
                       dateFormat="dd.mm.yy" showButtonBar/>


            {timeLine ?
                timeLine.length > 0 ?
                <VerticalTimeline>
                    {timeLine.map((row) => {
                        return (<VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{background: row.backgroundColor, color: '#fff'}}
                            contentArrowStyle={{borderRight: '7px solid  ' + row.backgroundColor}}
                            date={row.start_time + " - " + row.end_time}
                        >
                            <h4 className="vertical-timeline-element-subtitle">{row.title}</h4>
                        </VerticalTimelineElement>)
                    })}


                </VerticalTimeline>
                    : <p>Информация скоро появится</p>
                : <Loader/>}
        </div>
    )
}