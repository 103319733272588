import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import axios from 'axios'
import Loader from "../components/Loader";
import {ReactComponent as LogoSvg} from "../assets/icons/logo.svg";
import {ReactComponent as PaperClip} from "../assets/fonts/feather/paperclip.svg";
import {setBreadcrumbs, setTitle} from "../actions/main";

export default function NewsItem(props) {

    const {newsId} = useParams()

    const [news, setNews] = useState(null)
    const dispatch = useDispatch()


    dispatch(setTitle('Новости'))
    dispatch(setBreadcrumbs('/news'))
    dispatch(setBreadcrumbs([]))


    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData) {
                let n = userData.department.news.filter(e => e.id == newsId);
                if (n.length > 0) {
                    console.log(n[0])
                    setNews(n[0])
                }
            }
        }
        fetchData()
    }, [userData])


    return (
        news ? <>


            <div className={'news-page block'} style={{fontSize: '.7em'}}>
                <small className={'news_date'}>{news.date_public}</small>
                <h3>{news.title}</h3>
                <div dangerouslySetInnerHTML={{__html: news.text}}></div>

                {news.files &&
                    <div className={'news__files'}>

                        {news.files && news.files.map((el) => {
                            if (el.name) {
                                return (
                                    <div className={'news__files-item'}>
                                        <i>
                                            <PaperClip/>
                                        </i>
                                        <a href={el.filePath} target={'_blank'}>{el.name}</a>
                                    </div>
                                )
                            }
                        })}
                    </div>
                }
            </div>
        </> : <Loader/>
    )
}