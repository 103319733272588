import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import axios from 'axios'
import Loader from "../components/Loader";
import {ReactComponent as LogoSvg} from "../assets/icons/logo.svg";
import {setBreadcrumbs, setTitle} from "../actions/main";

export default function Report(props) {

    const {reportId} = useParams()

    const [report, setReport] = useState(null)
    const urlImage = 'https://crm.discoveryschool.ru';
    const dispatch = useDispatch()

    dispatch(setTitle(report ? report.fields.title : ''))
    dispatch(setBreadcrumbs('/reports'))

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'https://crm.discoveryschool.ru/api/default/get-report?id=' + reportId,
            );
            setReport(result.data);
        }
        fetchData()
    }, [])


    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    return (
        <>
            {report ? <div className={'report-page'}>

                <LogoSvg className={'report-logo'}/>


                <div className="report report_full">
                    <div className="report-image">
                        <img src={urlImage + report.images.header} alt=""/>
                    </div>

                    <div className="container">

                        <div className="report__title">
                            <div className="title">{report.fields.title}</div>
                            <div className="date">
                                <span>Moscow</span>
                                <span>{report.fields.date_public}</span>
                            </div>
                        </div>

                        <div className="report__main">
                            <div className="report__aside">

                                {report.props.main_left_title.value &&
                                    <div className="report__block">
                                        <div className="title text-center"
                                             style={{background: '#F99E3A'}}>{report.props.main_left_title.value}
                                        </div>
                                        <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.main_left_text.value}}>

                                        </div>
                                    </div>
                                }

                                {report.props.main_left_title_2.value &&
                                    <div className="report__block">
                                        <div className="title text-center"
                                             style={{background: '#6EBE59'}}>{report.props.main_left_title_2.value}
                                        </div>
                                        <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.main_left_text_2.value}}>
                                        </div>
                                    </div>
                                }
                            </div>
                            {report.props.main_title.value &&
                                <div className="report__content">
                                    <div className="report__block">
                                        <div className="title"
                                             style={{background: '#7E61A9'}}>{report.props.main_title.value}
                                        </div>
                                        <div className="content" dangerouslySetInnerHTML={{__html:report.props.main_text.value}}>

                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        {report.images.image1 &&
                            <div className="report__pictures">
                                <a href={urlImage + report.images.image1} target={'_blank'} ><img src={urlImage + report.images.image1} alt=""/></a>
                                <a href={urlImage + report.images.image2} target={'_blank'} ><img src={urlImage + report.images.image2} alt=""/></a>
                                <a href={urlImage + report.images.image3} target={'_blank'} ><img src={urlImage + report.images.image3} alt=""/></a>
                                <a href={urlImage + report.images.image4} target={'_blank'} ><img src={urlImage + report.images.image4} alt=""/></a>
                            </div>
                        }

                        <div className="report__main">


                            {report.props.content_title_1.value &&
                                <div className="report__col-3">
                                    <div className="report__block">
                                        <div className="title text-center"
                                             style={{background: '#F99E3A'}}>{report.props.content_title_1.value}
                                        </div>
                                        <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_1.value}}>
                                        </div>
                                    </div>
                                </div>
                            }


                            {report.props.content_title_2.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#0089BC'}}>{report.props.content_title_2.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_2.value}}>
                                    </div>
                                </div>
                            </div>

                            }


                            {report.props.content_title_3.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#7E61A9'}}>{report.props.content_title_3.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_3.value}}>
                                    </div>
                                </div>
                            </div>

                            }


                        </div>

                        <div className="report__main">

                            {report.props.content_title_4.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#6EBE59'}}>{report.props.content_title_4.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_4.value}}>
                                    </div>
                                </div>
                            </div>
                            }
                            {report.props.content_title_5.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#0089BC'}}>{report.props.content_title_5.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_5.value}}>
                                    </div>
                                </div>
                            </div>
                            }
                            {report.props.content_title_6.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#0089BC'}}>{report.props.content_title_6.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text_6.value}}>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>


                        <div className="report__main photos">
                            {report.props.content_title.value &&
                            <div className="report__col-3 report__col-6">
                                <div className="report__block">
                                    <div className="title text-center"
                                         style={{background: '#333435'}}>{report.props.content_title.value}
                                    </div>
                                    <div className="content text-center" dangerouslySetInnerHTML={{__html: report.props.content_text.value}}>
                                    </div>
                                </div>
                            </div>
                            }
                            {report.props.images.value &&
                            <div className="report__photos">
                                <strong className="text-center">The photos of this week</strong>
                                {/*<img src="/reports/br.svg" alt="" />*/}
                                    <a href={report.props.images.value} target={'_blank'} className="btn">Watch Photos</a>
                            </div>
                            }
                        </div>


                    </div>


                    {report.props.children_1 && report.props.star_title_1.value &&


                    <div className="report__stars">

                        <div className="container">
                            <div className="title">Stars of the Week</div>


                            <div className="report__stars-container">
                                <div className="report__star">
                                    <div className="image">
                                        <img src={urlImage + report.props.children_1} alt="" />
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.star_title_1.value}}></div>
                                </div>
                                <div className="report__star">
                                    <div className="image">
                                        <img src={urlImage + report.props.children_2}  alt="" />
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.star_title_2.value}}></div>
                                </div>
                                <div className="report__star">
                                    <div className="image">
                                        <img src={urlImage + report.props.children_3}  alt="" />
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.star_title_3.value}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    }
                    <div className="container">
                        {report.props.center_title_1.value &&
                        <div className="report__teacher">
                            <div className="report__teacher-content">
                                <div className="title">{report.props.center_title_1.value}</div>
                                <div className="content"  dangerouslySetInnerHTML={{__html: report.props.center_text_1.value}}>

                                </div>
                            </div>
                            <div className="report__teacher-img">
                                <img src={urlImage + report.images.image5} alt="" />
                            </div>
                        </div>
                        }

                        <div className="report__main">
                            {report.props.bottom_title_1.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title"
                                         style={{background: '#0089BC'}}>{report.props.bottom_title_1.value}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.bottom_text_1.value}}>

                                    </div>
                                </div>
                            </div>
                            }
                            {report.props.bottom_title_2.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title"
                                         style={{background: '#F99E3A'}}>{report.props.bottom_title_2.value}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.bottom_text_2.value}}>

                                    </div>
                                </div>
                            </div>
                            }

                        </div>

                        <div className="report__main">
                            {report.props.bottom_title_3.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title"
                                         style={{background: '#7E61A9'}}>{report.props.bottom_title_3.value}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.bottom_text_3.value}}>

                                    </div>
                                </div>
                            </div>
                            }
                            {report.props.bottom_title_4.value &&
                            <div className="report__col-3">
                                <div className="report__block">
                                    <div className="title"
                                         style={{background: '#6DC636'}}>{report.props.bottom_title_4.value}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: report.props.bottom_text_4.value}}>

                                    </div>
                                </div>
                            </div>
                            }

                        </div>

                        {report.props.bottom_title.value &&
                        <div className="report__footer">
                            <div className="title">{report.props.bottom_title.value}</div>
                            <div dangerouslySetInnerHTML={{__html: report.props.bottom_text.value}}></div>
                        </div>
                        }

                    </div>

                </div>


            </div> : <Loader/>}
                </>
                )
                }