import React, {useEffect, useState} from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import {userService} from "../services/UserService";
import axios from "axios";
import Loader from "../components/Loader";
import {Link} from "react-router-dom";

export default function Psychologist(props) {

    const dispatch = useDispatch()

    dispatch(setTitle('Отчеты психолога'))
    dispatch(setBreadcrumbs([]))
    const [reports, setReports] = useState([])
    const [activeGroup, setActiveGroup] = useState(false)

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        setReports(false)
        if (userData && !activeGroup) {
            userData.children.map((el) => {
                if (el.id == userService.GetCurrentChild()) {
                    setActiveGroup(el.active_group_id);
                }
            })
        }
        const fetchData = async () => {
            if (userData && userData.children && activeGroup) {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getPsychologist?department_id=' + userData.department_id + '&group_id=' + activeGroup,
                );
                setReports(result.data);
            }
        }
        fetchData()

    }, [userData, activeGroup])


    return (
        <>
            {reports && reports.length === 0 && <div className={'center-block'}>
                <p>Пока нет отчетов...</p>
            </div>}
            {reports ? reports.map((report) => {



                return (
                    <Link to={'/psychologist/' + report.id} className={'report report-mini report_list_item block'}>
                        <img className="report__img" src={'https://crm.discoveryschool.ru' + report.header}/>
                        <div className="report__data">
                            <div className="report__name">{report.title}</div>
                            <div className="report__date">{report.date_public}</div>
                        </div>
                    </Link>
                )

            }) : <Loader/>}
        </>
    )
}