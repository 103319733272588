import {LOGIN, LOGOUT, UPDATE_INFO} from "./types";

export const userLogin = content => ({
    type: LOGIN,
    userData: content
})

export const userUpdateInfo = content => ({
    type: UPDATE_INFO,
    userData: content
})

export const userLogout = content => ({
    type: LOGOUT
})