import {sendRequest} from "./Sender";
import {useDispatch, useSelector} from "react-redux";
import {userLogout, userUpdateInfo} from "../actions/user";
import {useNavigate} from "react-router-dom";

export const userService = {
    Auth,
    Logout,
    UpdateInfo,
    GetCurrentDepartment,
    SetCurrentDepartment,
    GetAllDepartments,
    GetCurrentChild,
    SetCurrentChild
}

function Auth(token){
    localStorage.setItem('user', token);
}

function Logout(){
    localStorage.removeItem('child')
    localStorage.removeItem('user');
}

function GetCurrentDepartment(){
    return JSON.parse(localStorage.getItem('currentDepartment'))
}

function SetCurrentDepartment(department){
    localStorage.setItem('currentDepartment', department)
}



function GetCurrentChild(){
    return localStorage.getItem('child')
}

function SetCurrentChild(child){
    localStorage.setItem('child', child)
}

function UpdateInfo(){
    const dispatch = useDispatch()
    sendRequest('getClientInfo', 'GET', {
        token: localStorage.getItem('user')
    }).then(response => {

        if (response) {
            dispatch(userUpdateInfo(response))
            return true;
        } else {
            Logout();
            dispatch(userLogout())
        }
    })

}

function GetAllDepartments(){
    return sendRequest('departments', 'GET', {
        'access-token': localStorage.getItem('user')
    })
}