import {SET_BREADCRUMBS, SET_TITLE, TOGGLE_ASIDE, TOGGLE_DARK} from "./types";


export const toggleAside = content => ({
    type: TOGGLE_ASIDE,
    asideStatus: content
})

export const toggleDark = content => ({
    type: TOGGLE_DARK,
    darkStatus: content
})

export const setTitle = content => ({
    type: SET_TITLE,
    pageTitle: content
})

export const setBreadcrumbs = content => ({
    type: SET_BREADCRUMBS,
    breadcrumbs: content
})