import React, {useEffect, useState} from "react";
import { InputSwitch } from 'primereact/inputswitch';
import 'primereact/inputswitch/inputswitch.min.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-purple/theme.css'
import {useSelector} from "react-redux";
import {sendRequest} from "../services/Sender";


export default function Anketa(props){

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    const [ifAllergy, setIfAllergy] = useState(false)
    const [submitClass, setSumbitClass] = useState('')
    const [submitText, setSubmitText] = useState('Сохранить')
    const [ifReaction, setIfReaction] = useState(false)
    const [ifIntolerance, setIfIntolerance] = useState(false)
    const [ifToilet, setIfToilet] = useState(false)
    const [ifInjuries, setIfInjuries] = useState(false)
    const [comment, setComment] = useState(false)

    useEffect(() => {
        if(userData && userData.children.length > 0){
            let ch = userData.children[0];
            setComment(ch.physiological_feature_comment)
            if(ch.if_allergy === 1)
                ch.if_allergy = true;
            setIfAllergy(ch.if_allergy)
            if(ch.if_reaction === 1)
                ch.if_reaction = true;
            setIfReaction(ch.if_reaction)
            if(ch.if_intolerance === 1)
                ch.if_intolerance = true;
            setIfIntolerance(ch.if_intolerance)
            if(ch.if_toilet === 1)
                ch.if_toilet = true;
            setIfToilet(ch.if_toilet)
            if(ch.if_injuries === 1)
                ch.if_injuries = true;
            setIfInjuries(ch.if_injuries)
        }
    }, [userData])

    const handleSubmit = (e) => {
        setSumbitClass('disabled');
        setSubmitText('Отправка...');
        e.preventDefault()
        sendRequest('anketa', 'GET', {
            if_allergy: ifAllergy,
            if_reaction: ifReaction,
            if_intolerance: ifIntolerance,
            if_toilet: ifToilet,
            if_injuries: ifInjuries,
            physiological_feature_comment: comment,
            child_id: userData.children[0].id
        }).then(response => {
            setSumbitClass('')
            setSubmitText('Сохранено!')
        })
    }

    return (
        <form onSubmit={handleSubmit} className={'block options-list'}>
            <h2>Анкета ребенка</h2>
            <label className={'options-list__item'}>
                <span>Аллергия</span>
                <InputSwitch checked={ifAllergy} onChange={(e) => setIfAllergy(e.value)} />
            </label>
            <label className={'options-list__item'}>
                <span>Реакция на продукты питания или лекарственные препараты</span>
                <InputSwitch checked={ifReaction} onChange={(e) => setIfReaction(e.value)} />
            </label>
            <label className={'options-list__item'}>
                <span>Непереносимость каких-либо веществ</span>
                <InputSwitch checked={ifIntolerance} onChange={(e) => setIfIntolerance(e.value)} />
            </label>
            <label className={'options-list__item'}>
                <span>Особенности посещения туалета</span>
                <InputSwitch checked={ifToilet} onChange={(e) => setIfToilet(e.value)} />
            </label>
            <label className={'options-list__item'}>
                <span>Наличие травм / хронических заболеваний</span>
                <InputSwitch checked={ifInjuries} onChange={(e) => setIfInjuries(e.value)} />
            </label>


            <label htmlFor="childComment">Комментарий</label>
            <textarea id={'childComment'} cols="30" rows="10" onChange={(e) => setComment(e.target.value)} value={comment}></textarea>


            <button className={"btn btn-primary " + submitClass} type={'submit'}>{submitText}</button>
        </form>
    )

}