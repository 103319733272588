import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {setBreadcrumbs, setTitle} from "../actions/main";
import Additional from "../components/Additional";
import {Link} from "react-router-dom";
import {ReactComponent as LogoSvg} from '../assets/icons/logo.svg'
import Kindegarten from "../components/Kindegarten";
import {userService} from "../services/UserService";
import {ReactComponent as RightIcon} from "../assets/fonts/feather/chevron-right.svg";

export default function Child(props) {

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    const dispatch = useDispatch()
    const [curChild, setCurChild] = useState(userService.GetCurrentChild())

    dispatch(setTitle('Мой ребенок'))
    dispatch(setBreadcrumbs([]))


    useEffect(() => {
        console.log('ud', userData)
        if (userService.GetCurrentChild() === null && userData) {
            userService.SetCurrentChild(userData.children[0].id)
            setCurChild(userData.children[0].id)
        }
    }, [userData])

    function changeChild(ch) {
        userService.SetCurrentChild(ch)
        setCurChild(ch)
    }

    return (

        <div className={'childs'}>
            <LogoSvg className={'childs__logo'}/>
            {userData && <div className={'childs-container'}>

                {userData.children.map((child) => {
                    if (child.id == curChild) {
                        return (
                            <div className="child ">


                                <div className="child__row block">
                                    <div className="child__avatar">
                                        <img src={"https://crm.discoveryschool.ru" + child.avatar} alt=""/>
                                    </div>
                                    <div className="child__maininfo">
                                        <div className="child__fio">{child.fio}</div>
                                        <div className="child__birthday">{child.birthday_text}</div>
                                        {/*<Link to={'/anketa'} className={'btn'}>Анкета</Link>*/}

                                        {userData.children.length > 1 && <>
                                            <h4 className={'another_child'}>Выбрать другого ребенка:</h4>

                                            {userData.children.map((ch) => {
                                                if (ch.id != curChild) {
                                                    return <div className={'additional-item'}>
                                                        <a href="#" onClick={() => changeChild(ch.id)}
                                                           className={'additional-item__link'}>
                                                        <span
                                                            className="additional-item__name">{ch.fio}</span>
                                                            <i className="additional-item__icon">
                                                                <RightIcon/>
                                                            </i>
                                                        </a>
                                                    </div>
                                                }
                                            })}


                                        </>}
                                    </div>
                                </div>


                                <div className="block department">
                                    <h3 className="child__department">Discovery {userData.department.name}</h3>

                                    <div className="child__additional-info">

                                        <div
                                            className="child__additional-row">Адрес: {userData.department.address}</div>
                                        <div className="child__additional-row">Режим
                                            работы: {userData.department.worktime_from} - {userData.department.worktime_to}</div>
                                        <a href={"tel:" + userData.department.phone}
                                             className="child__additional-row department__phone block-footer-add">{userData.department.phone}</a>
                                        <a href={"https://wa.me/" + userData.department.phone.replace('+', '')}
                                             className="child__additional-row department__phone block-footer-add">Написать
                                            в WhatsApp
                                        </a>
                                        {userData.department.lat && userData.department.lng && 1 == 2 &&
                                            <a href={"geo:" + userData.department.lat + ',' + userData.department.lng}
                                               className="child__additional-row department__map block-footer-add">Проложить
                                                маршрут</a>
                                        }
                                    </div>
                                </div>


                                <div className="child__additionals">
                                    {userData.contracts.filter(c => !c.additional_id && c.child.id == curChild && c.status == 100).length > 0 &&

                                        <div className="block  block_f">
                                            <h3>Детский клуб</h3>
                                            {userData.contracts.filter(c => !c.additional_id && c.child.id == curChild && c.status == 100).map((el) => {

                                                return <Kindegarten contract={el}/>
                                            })}
                                            <>
                                                {/*<Link to={'/timeline'} className={'block-footer-add'}>Расписание</Link>*/}
                                                {/*<Link to={'/food'} className={'block-footer-add'}>Меню на неделю</Link>*/}
                                            </>

                                            {/*<Link to={'/additionals'} className={'block-footer-add'}>Добавить</Link>*/}
                                        </div>
                                    }

                                    <div className="block block_o">
                                        <h3>Дополнительные занятия</h3>
                                        {userData.contracts.filter(c => c.additional_id && c.additional && c.additional.type === 'additional').map((el) => {
                                            return <Additional additional={el.additional} contract={el}/>
                                        })}
                                        <Link to={'/additionals'} className={'mt-3 btn btn-primary w-100'}>Добавить</Link>
                                    </div>
                                    <div className="block block_s">
                                        <h3>Индивидуальные занятия</h3>
                                        {userData.contracts.filter(c => c.additional_id && c.additional && c.additional.type === 'individual').map((el) => {
                                            return <Additional additional={el.additional} contract={el}/>
                                        })}
                                        <Link to={'/individuals'} className={'mt-3 btn btn-primary w-100'}>Добавить</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}


            </div>}
        </div>
    )
}