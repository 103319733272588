import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ReactComponent as RightIcon} from "../assets/fonts/feather/chevron-right.svg";
import {useSelector} from "react-redux";

export default function Kindegarten(props){

    const [count, setCount] = useState(null)

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        if(props.contract && userData && userData.children){
            let c = 0
            setCount(c)
        }
        console.log(props)
    }, [userData])



    return (
        <div className={'additional-item'}>
            <Link to={'/kindergarten/' + props.contract.id} className={'additional-item__link'}>
                <span className={'additional-item__name'}>{props.contract.group_object.name}</span>

                <i className={'additional-item__icon'}><RightIcon/></i>
            </Link>
        </div>
    )

}