import {UsersService} from "../services/UsersService";
import React from "react";
import Loader from "../components/Loader";
import withRouter from "../components/WithRouter";
import FormGroup from "../components/FormGroup";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import {userService} from "../services/UserService";
import Tablist from "../components/Tablist";
import {Link, Outlet} from "react-router-dom";
import {setTitle, setBreadcrumbs} from "../actions/main";
import {connect} from "react-redux";

class UserEdit extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            user: null,
            positions: [],
            auths: false,
            tabs: [
                {title: 'Документы', id: 'documents'},
                {title: 'Пароль', id: 'password'},
                {title: 'Финансы', id: 'money'},
                {title: 'Права', id: 'access'},
                {title: 'Обучение', id: 'learning'}
            ],
            activeTab: props.router.location.pathname.split('/')[3] ? props.router.location.pathname.split('/')[3] : false
        }


        UsersService.GetById(props.router.params.userId).then((response) => {
            this.setState({'user': response})
            this.props.setTitle(response.username)
            this.props.setBreadcrumbs([
                {'label': 'Сотрудники', link: 'users'},
                {'label': response.username, link: 'users/' + response.id},
            ])
        })




        this.handleTabClick = this.handleTabClick.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)

        this.props.setTitle('Редактировать пользователя')

    }

    componentDidMount() {
        let cleanupFunction = false;
        const fetchData = async () => {
            try {
                UsersService.GetPositions(23).then((response) => {
                    this.setState({positions: response})
                })
            } catch (e) {

            }
        }
        fetchData();

        // функция очистки useEffect
        return () => cleanupFunction = true;
    }


    handlePositionSelect() {

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(name, 'name')
        console.log(value, 'value')

        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                [name]: value
            }
        });
    }

    handleTabClick(tab) {
        console.log(tab)
        this.setState({
            activeTab: tab
        })
        this.props.router.navigate(tab)
    }

    render() {



        if (this.state.user !== null) {
            return (
                <div className={'edit-profile'}>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">


                                <div className="card-body">

                                    <form action="" className={'theme-form'}>
                                        <div className="profile-title">
                                            <div className="media">
                                                <img className="img-70 rounded-circle" alt=""
                                                     src={'https://crm.discoveryschool.ru' + this.state.user.avatar}/>
                                                <div className="media-body">
                                                    <h3 className="mb-1 f-20 txt-primary">{this.state.user.username}</h3>
                                                    <p className="f-12">{(this.state.user && this.state.user.position) ? this.state.user.position.name : ''}</p>
                                                </div>
                                            </div>
                                        </div>



                                        <FormGroup label={'ФИО'}>
                                            <input type={'text'} className={'form-control'} name={'username'} onChange={this.handleInputChange}
                                                   value={this.state.user.username}/>
                                        </FormGroup>

                                        <FormGroup label={'Телефон (логин)'}>
                                            <PhoneInput specialLabel={''}
                                                        value={this.state.user.phone_prefix + this.state.user.phone}/>
                                        </FormGroup>


                                        <FormGroup label={'Email'}>
                                            <input type={'email'} onChange={this.handleInputChange} name={'email'} className={'form-control'}
                                                   value={this.state.user.email} />
                                        </FormGroup>

                                        <hr/>

                                        <FormGroup label={'Должность'}>
                                            <select className={'form-select'}>

                                            </select>
                                        </FormGroup>


                                        <div className="form-check checkbox m-b-30">
                                            <input type="checkbox" name={'crm_access'} id={'main-auth'} onChange={this.handleInputChange} checked={this.state.user.crm_access} className="form-check-input"/>
                                            <label htmlFor={'main-auth'} className="form-check-label">Доступ к системе</label>
                                        </div>

                                        <button type={'submit'} className={'btn btn-primary'}>Сохранить</button>


                                    </form>
                                </div>


                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card project-list">


                                <div className="row">

                                    <div className="col-md-6 p-0">

                                        <ul className="nav nav-tabs border-tab">
                                            {this.state.tabs.map((tab, k) => {
                                                return (
                                                    <li className={'nav-item'} key={'tab-' + k}>
                                                        <a href='#' onClick={() => this.handleTabClick(tab.id)}
                                                           className={'nav-link ' + (this.state.activeTab === tab.id ? 'active' : '')}>{tab.title}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>

                                    </div>

                                </div>

                            </div>

                            <div className="card">

                                <div className="card-body">
                                    {<Outlet/>}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            )
        } else {
            return (
                <Loader/>
            )
        }
    }
}

export default connect(
    null,
    {setTitle, setBreadcrumbs}
)(withRouter(UserEdit))

