import React from "react";

export default function Contract(props){

    let contract = props.contract;

    return (
        <div className={'contract contract-item contract-status-' + contract.status}>
            {!props.hideTitle &&
            <h4
                className={'contract__group mb-3'}>{contract.group_object ? contract.group_object.name : contract.additional.name}</h4>}
            <div className={'contract__to'}><span>Срок действия:</span> с {contract.date_from} до {contract.date_to}</div>
            <div className={'contract__to'}><span>Стоимость:</span> {contract.price.toLocaleString('ru-RU', {
                style: 'currency',
                currency: 'RUB'
            })}</div>
            {contract.group_object &&
                <div className={'contract__to'}><span>Посещение:</span> {contract.visiting}</div>
            }
            <div className={'contract__to'}><span>Статус:</span> {contract.status_public}</div>
        </div>
    )
}