import {Route, Navigate, Outlet} from "react-router-dom";

import React from "react";
import CrmLayout from "../layout/CrmLayout";

export  const ProtectedRoute = ({
                            redirectPath = '/login',
                            children,
                        }) => {
    if (!localStorage.getItem('user')) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? <CrmLayout>children</CrmLayout> : <CrmLayout><Outlet /></CrmLayout>;
};