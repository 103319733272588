import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import Loader from "../components/Loader";
import {Link} from "react-router-dom";

export default function News(props) {

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    const dispatch = useDispatch()

    dispatch(setTitle('Новости'))
    dispatch(setBreadcrumbs([]))

    return (
        userData ? <div className={'news'}>

            {userData.department.news.length > 0 ?
                <>
                    {userData.department.news.map((el) => {
                        return (
                            <Link to={'/news/' + el.id} className={'report'}>
                                <div className="report__name">{el.title}</div>
                                <div className="report__date">{el.date_public}</div>
                            </Link>
                        )
                    })}</> : <div className={'center-block'}>
                    <p>Ждем новостей 😊</p>
                </div>}
        </div> : <Loader/>
    )
}