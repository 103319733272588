import { Routes, Route, Link } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import {Provider} from "react-redux";
import store from './stores/userStore';
import React from 'react'
import { ScrollRestoration } from "react-router-dom";
import './assets/css/vendors/themify.css'
import './assets/css/vendors/bootstrap.css'
import './assets/css/style.css';
import './assets/css/responsive.css'
import './assets/css/color-1.css'
import './assets/css/custom.css';
import './assets/css/vendors/feather-icon.css';
import './assets/css/vendors/font-awesome.css'




import {ProtectedRoute} from "./components/PrivateRoute";
import Users from "./pages/Users";
import UserEdit from "./pages/UserEdit";
import UserEditPassword from "./pages/UserEditPassword";
import UserEditAccess from "./pages/UserEditAccess";
import UserEditLearning from './pages/UserEditLearning'
import UserEditMoney from './pages/UserEditMoney'
import Child from "./pages/Child";
import HomePage from "./pages/HomePage";
import Reports from "./pages/Reports";
import Report from "./pages/Report";
import Finances from "./pages/Finances";
import Additionals from "./pages/Additionals";
import Additional from "./pages/Additional";
import News from "./pages/News";
import NewsItem from "./pages/NewsItem";
import Settings from "./pages/Settings";
import Individuals from "./pages/Individuals";
import Exit from "./pages/Exit";
import Attendance from "./pages/Attendance";
import Anketa from "./pages/Anketa";
import Kindergarten from "./pages/Kindergarten";
import Feedback from "./pages/Feedback";
import ScrollToTop from "./components/ScrollToTop";
import Timeline from "./pages/Timeline";
import Food from "./pages/Food";
import Logopedist from "./pages/Logopedist";
import LogopedistItem from "./pages/LogopedistItem";
import PsychologistItem from "./pages/PsychologistItem";
import Psychologist from "./pages/Psychologist";

const IS_TEST = true;

function Home() {
  return (
      <>
        <main>
          <h2>Welcome to the homepage!</h2>
          <p>You can do this, I believe in you.</p>
        </main>
        <nav>
          {/*<Link to="/about">About</Link>*/}
        </nav>
      </>
  );
}


function App() {
  return (
    <Provider store={store}>
      <Routes>

          <Route element={<ProtectedRoute />}>
            <Route path={'/'} element={<HomePage />}/>
            <Route path={'/about'} element={<Child />}/>
            <Route path={'/finances'} element={<Finances />}/>
            <Route path={'/reports'} element={<Reports />}/>
            <Route path={'/reports/:reportId'} element={<Report />}/>
            <Route path={'/kindergarten/:contractId'} element={<Kindergarten />}/>
            <Route path={'/individuals'} element={<Individuals />}/>
            <Route path={'/additionals'} element={<Additionals />}/>
            <Route path={'/additionals/:additionalId'} element={<Additional />}/>
            <Route path={'/news'} element={<News />}/>
            <Route path={'/news/:newsId'} element={<NewsItem />}/>
            <Route path={'/settings'} element={<Settings />}/>
            <Route path={'/exit'} element={<Exit />}/>
            <Route path={'/attendance'} element={<Attendance />}/>
            <Route path={'/anketa'} element={<Anketa />}/>
            <Route path={'/feedback'} element={<Feedback />}/>
            <Route path={'/timeline'} element={<Timeline />}/>
            <Route path={'/food'} element={<Food />}/>
            <Route path={'/logopedist'} element={<Logopedist />}/>
              <Route path={'/logopedist/:logopedistId'} element={<LogopedistItem />}/>
            <Route path={'/psychologist'} element={<Psychologist />}/>
              <Route path={'/psychologist/:psychologistId'} element={<PsychologistItem />}/>
            {/*<Route path={'users'} element={<Users />}/>*/}
            {/*<Route path={'users/:userId'} element={<UserEdit />}>*/}
            {/*    <Route path={'password'} element={<UserEditPassword/>} />*/}
            {/*    <Route path={'access'} element={<UserEditAccess/>} />*/}
            {/*    <Route path={'money'} element={<UserEditMoney/>} />*/}
            {/*    <Route path={'learning'} element={<UserEditLearning/>} />*/}
            {/*</Route>*/}
          </Route>
        <Route path="/login" element={<LoginPage />} />

      </Routes>
    </Provider>
  );
}

export default App;
