import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import axios from 'axios'
import Loader from "../components/Loader";
import {ReactComponent as LogoSvg} from "../assets/icons/logo.svg";
import {setBreadcrumbs, setTitle} from "../actions/main";

export default function LogopedistItem(props) {

    const {logopedistId} = useParams()

    const [report, setReport] = useState(null)
    const urlImage = 'https://crm.discoveryschool.ru';
    const dispatch = useDispatch()

    dispatch(setTitle(report ? report.fields.title : ''))
    dispatch(setBreadcrumbs('/logopedist'))

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'https://crm.discoveryschool.ru/api/default/get-report?id=' + logopedistId,
            );
            setReport(result.data);
        }
        fetchData()
    }, [])


    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    return (
        <>
            {report ? <div className={'report-page report-logopedist'}>

                <LogoSvg className={'report-logo'}/>


                <div className="report report_full ">

                    <div className={'report-logopedist__about'}>
                        <div className="report-image">
                            <img src={urlImage + report.images.header} alt=""/>
                        </div>


                        {report.props.teacher_about && report.props.teacher_about.value &&
                            <div className="report__block">
                                <div className={'content'}
                                     dangerouslySetInnerHTML={{__html: report.props.teacher_about.value}}></div>
                            </div>
                        }
                    </div>


                    {report.props.content_text_1.value &&
                        <div className="report__col-3">
                            <div className="report__block">
                                <div className="title text-center"
                                     style={{background: 'rgb(249, 158, 58)'}}>Лексические темы
                                </div>
                                <div className="content text-center"
                                     dangerouslySetInnerHTML={{__html: report.props.content_text_1.value}}>
                                </div>
                            </div>
                        </div>
                    }

                    {report.props.content_text_2.value &&
                        <div className="report__col-3">
                            <div className="report__block">
                                <div className="title text-center"
                                     style={{background: 'rgb(110, 190, 89)'}}>Направления работы
                                </div>
                                <div className="content text-center"
                                     dangerouslySetInnerHTML={{__html: report.props.content_text_2.value}}>
                                </div>
                            </div>
                        </div>
                    }

                    {report.props.content_text_3.value &&
                        <div className="report__col-3">
                            <div className="report__block">
                                <div className="title text-center"
                                     style={{background: '#0089BC'}}>Словарь для активного и пассивного усвоения
                                </div>
                                <div className="content text-center"
                                     dangerouslySetInnerHTML={{__html: report.props.content_text_3.value}}>
                                </div>
                            </div>
                        </div>
                    }

                    {report.props.content_text_4.value &&
                        <div className="report__col-3">
                            <div className="report__block">
                                <div className="title text-center"
                                     style={{background: 'rgb(126, 97, 169)'}}>Упражнения на расслабление, развитие
                                    координации, мелкую моторику рук
                                </div>
                                <div className="content text-center"
                                     dangerouslySetInnerHTML={{__html: report.props.content_text_4.value}}>
                                </div>
                            </div>
                        </div>
                    }

                </div>


            </div> : <Loader/>}
        </>
    )
}