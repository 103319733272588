import React from "react";

class Loader extends React.Component {

    render() {
        return (
            <div className="loader-box">
                <div className="loader-2">

                </div>
            </div>
        )
    }

}

export default Loader