import React, {useEffect, useState} from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import {userService} from "../services/UserService";
import axios from "axios";
import Loader from "../components/Loader";

export default function Food(props) {

    const dispatch = useDispatch()

    dispatch(setTitle('Меню питания'))
    dispatch(setBreadcrumbs([]))
    const [menu, setMenu] = useState([])

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        setMenu(false)
        const fetchData = async () => {
            if (userData && userData.children) {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getFoodMenu?department_id=' + userData.department_id,
                );
                setMenu(result.data);
            }
        }
        fetchData()
    }, [userData])


    return (
        <>

            {menu ?
                <>

                    {Object.keys(menu).map((dayName) => {
                        if (dayName !== 'image') {
                            return (
                                <div className={'block food-block'}>
                                    <h4>{dayName}</h4>
                                    <br/>
                                    <p>
                                        {Object.keys(menu[dayName]).map((foodType) => {
                                            return (
                                                <>
                                                    <strong>{foodType} </strong>
                                                    <ul>
                                                        {menu[dayName][foodType] ?

                                                            menu[dayName][foodType].split('\n').map((el) => {
                                                                if (el) {
                                                                    return (

                                                                        <li>{el}</li>
                                                                    )
                                                                } else {
                                                                    return ('Нет информации')
                                                                }
                                                            })

                                                            : 'Нет информации'}
                                                    </ul>
                                                </>
                                            )
                                        })}
                                    </p>
                                </div>
                            )
                        } else {
                            return (
                                <a href={'https://crm.discoveryschool.ru/upload/' + menu[dayName].filePath}
                                   target={'_blank'}>
                                    <img className={'w-100'}
                                         src={'https://crm.discoveryschool.ru/upload/' + menu[dayName].filePath}/>
                                </a>
                            )
                        }
                    })}
                </>
                : <Loader/>}
            {/*    <h4>2 апреля</h4>*/}
            {/*    <br/>*/}
            {/*    <p>*/}
            {/*    <strong>Завтрак</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Дружба каша</li>*/}
            {/*    </ul>*/}

            {/*    <strong>Фруктовый перекус</strong><br/><br/>*/}
            {/*    <strong>Обед</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Суп из брокколи</li>*/}
            {/*        <li>Салат овощной</li>*/}
            {/*        <li>Фрикадельки из индейки</li>*/}
            {/*        <li>Ригатони</li>*/}
            {/*    </ul>*/}
            {/*        <br/>*/}
            {/*    <strong>Ужин</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Ромштекс из говядины / Булгур со сливочным маслом</li>*/}
            {/*    </ul>*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className={'block food-block'}>*/}
            {/*    <h4>3 апреля</h4>*/}
            {/*    <br/>*/}
            {/*    <p>*/}
            {/*    <strong>Завтрак</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Каша рисовая</li>*/}
            {/*    </ul>*/}

            {/*    <strong>Фруктовый перекус</strong><br/><br/>*/}
            {/*    <strong>Обед</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Борщ постный</li>*/}
            {/*        <li>Салат овощной</li>*/}
            {/*        <li>Котлетки из индейки</li>*/}
            {/*        <li>Гречка</li>*/}
            {/*    </ul>*/}
            {/*        <br/>*/}
            {/*    <strong>Ужин</strong>*/}
            {/*    <ul>*/}
            {/*        <li>Ромштекс из говядины / Булгур со сливочным маслом</li>*/}
            {/*    </ul>*/}
            {/*    </p>*/}


        </>
    )
}