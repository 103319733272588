import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ReactComponent as RightIcon} from "../assets/fonts/feather/chevron-right.svg";
import {useSelector} from "react-redux";
import {userService} from "../services/UserService";

export default function Additional(props){

    const [count, setCount] = useState(null)

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    useEffect(() => {
        if(props.contract && userData && userData.children){
            let c = 0

            userData.children.filter(ch => ch.id == userService.GetCurrentChild())[0].service_count.filter(e => e.additional_id == props.additional.id).map((el) => {
                c+= parseInt(el.count);
            })
            setCount(c)
        }
    }, [userData])



    return (
        <div className={'additional-item'}>
            <Link to={'/additionals/' + props.additional.id} className={'additional-item__link'}>
                <span className={'additional-item__name'}>{props.additional.name}</span>
                {count !== null ? <div className={'service-count service-count_' + (count >= 0 ? 'plus' : 'minus')}>{count}</div> : ''}
                <i className={'additional-item__icon'}><RightIcon/></i>
            </Link>
        </div>
    )

}