function serializeRequestData(obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serializeRequestData(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}


export async function sendRequest(path, method = 'GET', data){
    try {

        let initData = {
            method: method,
            headers: {'Content-Type': 'application/xml'},
            // credentials: true
        };

        if(method === 'POST'){
            initData.body = JSON.stringify(data);
        } else {
            path+= '?' + serializeRequestData(data);
        }

        const response = await fetch('https://crm.discoveryschool.ru/_clientapi/'+path, initData);
        return await response.json();
    } catch (error){
        return error;
    }
}