import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import Loader from "../components/Loader";
import {Link} from "react-router-dom";
import {setBreadcrumbs, setTitle} from "../actions/main";
import {userService} from "../services/UserService";

export default function Reports(props) {

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    const [activeGroup, setActiveGroup] = useState(false)
    const [minDate, setMinDate] = useState(false)


    const dispatch = useDispatch()

    dispatch(setTitle('Отчеты'))
    dispatch(setBreadcrumbs([]))

    useEffect(() => {
        if(userData) {
            userData.children.map((el) => {
                if (el.id == userService.GetCurrentChild()) {
                    console.log('ag', el.active_group_id)
                    setActiveGroup(el.active_group_id);
                    setMinDate(el.active_group_from_date);
                }
            })
        }
    }, [userData])

    return (
        <div>
            {userData && userData.reports && userData.reports.length === 0 && <div className={'center-block'}>
                <p>Пока нет отчетов...</p>
            </div>}
            {userData && userData.reports ? userData.reports.map((report) => {

                if(report.group_id == activeGroup && report.datetime >= minDate) {

                    return (
                        <Link to={'/reports/' + report.id} className={'report report_list_item block'}>
                            <img className="report__img" src={'https://crm.discoveryschool.ru' + report.header}/>
                            <div className="report__data">
                                <div className="report__name">{report.title}</div>
                                <div className="report__date">{report.date_public}</div>
                            </div>
                        </Link>
                    )
                }
            }) : <Loader/>}
        </div>
    )
}