import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import Additional from "../components/Additional";
import Loader from "../components/Loader";

export default function Additionals(props) {

    const dispatch = useDispatch()

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    // dispatch(setTitle(''))
    dispatch(setTitle('Дополнительные занятия филиала'))
    // dispatch(setBreadcrumbs('/about'))

    return (
        <>

            <div className={'block'}>
                {/*<h3 className={''}>Доп. занятия</h3>*/}
                {userData && userData.department ?
                    userData.department.additionals.length > 0 ?
                    userData.department.additionals.filter(e => e.type === 'additional').map((ad) => {
                        let has = false
                        userData.contracts.filter(c => c.additional_id && c.additional && c.additional.type === 'additional').map((cad) => {

                            if(cad.additional_id === ad.id){
                                has = true
                            }
                        })
                        if(!has) {
                            return <Additional additional={ad}/>
                        }
                }) : <p>В клубе нет дополнительных занятий.</p> : <Loader/>}
            </div>

        </>
    )
}