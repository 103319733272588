import {createStore} from "redux";
import {LOGIN, LOGOUT, TOGGLE_ASIDE, TOGGLE_DARK, UPDATE_INFO, SET_TITLE, SET_BREADCRUMBS} from "../actions/types";

const initialState = {
    auth: { loggedIn: false, userData: null, logout: false },
    asideStatus: '',
    darkStatus: '',
    pageTitle: '',
    breadcrumbs: [],
    reports: []
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return { ...state, auth: {userData: action.userData, loggedIn: true, logout: false }}
        case UPDATE_INFO:
            return { ...state, auth: {userData: action.userData, loggedIn: true, logout: false }}
        case LOGOUT:
            return { ...state, auth: {userData: null, loggedIn: false, logout: true}}
        case TOGGLE_ASIDE:
            return { ...state, asideStatus: action.asideStatus}
        case TOGGLE_DARK:
            return { ...state, darkStatus: action.darkStatus}

        case SET_TITLE:
            return { ...state, pageTitle: action.pageTitle}
        case SET_BREADCRUMBS:
            return { ...state, breadcrumbs: action.breadcrumbs}
        default:
            return state
    }
}

let store = createStore(userReducer)



export default store

