import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import axios from "axios";
import Loader from "../components/Loader";
import Contract from "../components/Contract";
import {userService} from "../services/UserService";

export default function Additional(props) {

    const {additionalId} = useParams()
    const dispatch = useDispatch()
    const [additional, setAdditional] = useState(null)
    const [contracts, setContracts] = useState([])
    const [finances, setFinances] = useState([])
    const [journal, setJournal] = useState([])
    const [count, setCount] = useState(null)
    const [debt, setDebt] = useState(0)
    const [balance, setBalance] = useState(0)
    const [sended, setSended] = useState(false)

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    // dispatch(setTitle(' '))
    dispatch(setBreadcrumbs('/additionals'))

    useEffect(() => {
        if (userData) {
            setAdditional(userData.department.additionals.filter(e => e.id == additionalId)[0])
        }
    }, [userData])

    useEffect(() => {
        if (userData) {
            setContracts(userData.contracts.filter(e => e.additional_id == additionalId))
        }
    }, [userData])



    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.children && contracts.length > 0) {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getReceipts?children_id=' + userService.GetCurrentChild() + '&additional_id=' + additionalId,
                );
                setFinances(result.data);

                const result2 = await axios(
                    'https://crm.discoveryschool.ru/_api/getAttendance?children_id=' + userService.GetCurrentChild() + '&additional_id=' + additionalId,
                );
                setJournal(result2.data);

                let c = 0
                // TODO::Filter current child only
                userData.children[0].service_count.filter(e => e.additional_id == additionalId).map((el) => {
                    c+= parseInt(el.count);
                })
                setCount(c)

                if(c >= 0){
                    setBalance(c*contracts[0].price)
                }
                if(c < 0){
                    setDebt(c*contracts[0].price)
                }
            }
        }
        fetchData()
    }, [contracts])


    const sendHandler = function (e){
        axios(
            'https://crm.discoveryschool.ru/_api/send_additional_order?children_id=' + userService.GetCurrentChild() + '&additional_id=' + additionalId,
        );
        setSended(true)
    }

    return (
        additional ? <div className={'additional-page'}>

            <div className={'block'}>
                <h2 className={''}>{additional.name}</h2>
                <p>{additional.text}</p>


                {contracts.length === 0 &&
                    <>
{/*                        <p>*/}
{/*                            Китайский язык постепенно переходит в ранг обязательного, поэтому Discovery предлагает начать знакомство с этим сложным языком в юном возрасте.*/}
{/*</p>*/}
{/*                         <p>Наши группы рассчитаны на 3-5 человек.</p>*/}

{/*                            <p>Занятия ведет носитель языка.</p>*/}

{/*                        <p>Используется игровая методика, интерактивная доска, песни, красочные пособия и карточки.</p>*/}

{/*                        <p>Группа подбирается по возрасту и по уровню.</p>*/}

{/*                        <p>Ваш ребенок может посещать группу, начиная с 4 лет.</p>*/}

{/*                        <p><strong>Стоимость</strong>:<br/>*/}
{/*                            Пробное занятие: 500 ₽<br/>*/}

{/*                        Одно занятие: 1 600 ₽<br/>*/}

{/*                        Абонемент на месяц: 10 000 ₽ (8 занятий)*/}
{/*                        </p>*/}
                        {additional.base_price &&
                            <p><strong style={{fontSize: '1rem'}}>{additional.base_price} ₽</strong> - 1 занятие</p>
                        }
                        {/*{!additional.base_price && <p>Цену уточняйте</p>}*/}
                        {!sended ?
                        <button className={'btn btn-primary w-100'} onClick={sendHandler}>Оставить заявку</button>
                            : <button className={'btn block-footer-add w-100'}>Заявка отправлена!</button> }
                    </>
                }

                {contracts.length > 0 ?
                   <>
                        {contracts.map((c) => {
                            return <Contract hideTitle={true} contract={c}/>
                        })}

                    </> : ''}

                {balance > 0 && <div className={'balance'}>Баланс: {balance} ₽</div> }
                {debt < 0 && <div className={'debt service-count_minus'}>Задолженность {debt} ₽  <br/> (за {count * -1} занятия)</div> }




            </div>

            {journal.length > 0 ?
                <>

                    <div className="block">
                        <h3 className={''}>Журнал посещаемости</h3>
                        <table className="table">
                            <tbody>
                            {journal.map((row) => {
                                return (
                                    <tr key={'j' + row.id}>
                                        <td>{row.date_public}</td>
                                        <td className={'at-status_' + row.status}>{row.status_public}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                </> : ''}



            {finances.length > 0 && 1 == 2 ?
                <>

                    <div className="block">
                        <h3 className={''}>История платежей</h3>
                        {finances ? <table className={'table'}>
                            <tbody>
                            {finances.map((row) => {
                                    return (
                                        <tr key={'k' + row.id}>


                                            <td><small style={{padding: 0}}>{row.date_public}</small><br/>{row.category}
                                                <div dangerouslySetInnerHTML={{__html: row.table_comment}}
                                                     style={{padding: 0}}></div>
                                            </td>
                                            <td>
                                                <div style={{padding: 0}} className={'amount'}>

                                                    {row.amount_public}</div>
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table> : <Loader/>}

                    </div>
                </>
                : ''}


        </div> : <Loader/>
    )

}