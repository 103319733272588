import {ReactComponent as AwardSvg} from "../assets/icons/star.svg";
import {ReactComponent as HomeSvg} from "../assets/icons/home.svg";
import {ReactComponent as MoneySvg} from "../assets/icons/dollar-sign.svg";
import {ReactComponent as BookSvg} from "../assets/icons/book.svg";
import {ReactComponent as RubleSvg} from "../assets/icons/ruble2.svg";
import {ReactComponent as ChatSvg} from "../assets/icons/message-circle.svg";
import {ReactComponent as MenuSvg} from "../assets/icons/menu.svg";
import {ReactComponent as NewsSvg} from "../assets/fonts/feather/bell.svg";
import React from "react";
import {Link, NavLink} from "react-router-dom";

export default function Nav() {

    const items = [
        {
            label: 'Мой ребенок',
            icon: <HomeSvg />,
            link: '/about'
        },

        {
            label: 'Финансы',
            icon: <RubleSvg />,
            link: '/finances'
        },
        {
            label: 'Отчеты',
            icon: <BookSvg />,
            link: '/reports'
        },
        {
            label: 'Новости',
            icon: <NewsSvg />,
            link: '/news'
        },
        {
            label: 'Настройки',
            icon: <MenuSvg />,
            link: '/settings'
        },
    ]

    return (
        <ul className={'mainNav'}>
            {items.map((el, i) => {
                return (
                    <li className={''}>
                        <NavLink to={el.link}>
                            {el.icon}
                            <span>{el.label}</span>
                        </NavLink>
                    </li>
                )
            })}

        </ul>
    )
}
