import React from "react";
import PhoneInput from "react-phone-input-2";
import FormGroup from "../components/FormGroup";
import {Link} from 'react-router-dom';
import LoginForm from "../components/LoginForm";


export default function LoginPage() {
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-12">
                    <div className="login-card">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </div>
    )
}
