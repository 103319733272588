import {sendRequest} from "./Sender";

export const DepartmentService = {
    getGroups,
    getAdditionals
}

function getGroups(params){
    return sendRequest('groups', 'GET', {...params, 'access-token': localStorage.getItem('user')})
}

function getAdditionals(params){
    return sendRequest('additionals', 'GET', {...params, 'access-token': localStorage.getItem('user')})
}