import {sendRequest} from "./Sender";

export const UsersService = {
    GetList,
    GetById,
    GetAuths,
    GetHrAttendances,
    GetCash,
    GetServices,
    GetPositions
}

function GetList(params= []){
    console.log(params);
    return sendRequest('users', 'GET', {...params, 'access-token': localStorage.getItem('user')})
}

function GetById(id){
    return sendRequest('users/' + id, 'GET', {'access-token': localStorage.getItem('user')})
}

function GetAuths(){
    return sendRequest('users/auths', 'GET', {'access-token': localStorage.getItem('user')})
}

function GetHrAttendances(user_id){
    return sendRequest('hpa', 'GET', {'access-token': localStorage.getItem('user'), 'filter': {'user_id': user_id}, 'expand': 'planing, planing.course'})
}

function GetCash(user_id, department_id){
    return sendRequest('userCash', 'GET', {'access-token': localStorage.getItem('user'), 'filter': {'user_id': user_id, 'department_id': department_id}})
}

function GetServices(user_id, department_id){
    return sendRequest('userServices', 'GET', {'access-token': localStorage.getItem('user'), 'filter': {'user_id': user_id, 'department_id': department_id}, 'expand': 'additional, dgroup'})
}

function GetPositions(department_id){
    return sendRequest('userPositions', 'GET', {'access-token': localStorage.getItem('user'), 'filter': {'department_id': {'is': department_id}}})
}