import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setBreadcrumbs, setTitle} from "../actions/main";
import Additional from "../components/Additional";
import Loader from "../components/Loader";

export default function Individuals(props) {

    const dispatch = useDispatch()

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    // dispatch(setTitle(null))
    dispatch(setTitle('Индивидуальные занятия филиала'))
    // dispatch(setBreadcrumbs('/about'))

    return (
        <>


            <div className={'block'}>
                {/*<h3 className={''}>Индивидуальные занятия</h3>*/}
                {userData && userData.department ?
                    userData.department.additionals.length > 0 ?
                        userData.department.additionals.filter(e => e.type === 'individual').map((ad) => {
                            let has = false
                            userData.contracts.filter(c => c.additional_id && c.additional && c.additional.type === 'individual').map((cad) => {

                                if(cad.additional_id === ad.id){
                                    has = true
                                }
                            })
                            if(!has) {
                                return <Additional additional={ad}/>
                            }
                        }) : <p>В клубе нет индивидуальных занятий.</p> : <Loader/>}

            </div>
        </>
    )
}