import React, {useEffect, useState} from "react";
import { Calendar } from 'primereact/calendar';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import 'primeicons/primeicons.css'
import {setTitle} from "../actions/main";
import {userService} from "../services/UserService";

export default function Attendance(props){

    const [dates, setDates] = useState(null)

    const dispatch = useDispatch()
    dispatch(setTitle('Журнал посещаемости'))

    const userData = useSelector(function (state) {
        return state.auth.userData
    });

    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['пн', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        monthNamesShort: ['янв', 'фер', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
        today: 'сегодня',
        clear: 'очистить'
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.children) {
                const result = await axios(
                    'https://crm.discoveryschool.ru/_api/getAttendance?children_id=' + userService.GetCurrentChild(),
                );
                setDates(result.data);


            }
        }
        fetchData()
    }, [userData])

    const dateTemplate = function (date) {

        let d = dates.filter((e) => {

            return parseInt(e.date_day) === date.day && parseInt(e.date_month) === (date.month+1) && parseInt(e.date_year) === date.year
        })

        if (d.length > 0) {
            let cl = '';
            d.map((el) => {
               cl+= ' st-' + el.contract_type + '-' + el.status;
            });
            cl+= ' m-'+date.month;
            return (
                <div className={cl}><div>{date.day}</div></div>
            );
        } else {
            return (
                <div><div>{date.day}</div></div>
            );
        }
    }

    locale('ru')
    return (
        <>
            {dates ?
                <div className={'block blocko'}>
                    {/*<h3>Журнал посещений</h3>*/}
                    <Calendar dateTemplate={dateTemplate} inline monthNavigator={false} showButtonBar={false}
                              yearNavigator={false}></Calendar>
                </div>
            : <Loader />}
        </>

    )
}