import React from "react";
import {ReactComponent as AwardSvg} from "../assets/icons/star.svg";
import {ReactComponent as MoneySvg} from "../assets/icons/dollar-sign.svg";
import {ReactComponent as BookSvg} from "../assets/icons/book.svg";
import {ReactComponent as NewsSvg} from "../assets/fonts/feather/bell.svg";
import {ReactComponent as JournalSvg} from "../assets/fonts/feather/book.svg";
import {ReactComponent as HelpSvg} from "../assets/fonts/feather/help-circle.svg";
import {ReactComponent as LogoutSvg} from "../assets/fonts/feather/log-out.svg";
import {ReactComponent as PlusSvg} from "../assets/fonts/feather/plus.svg";
import {ReactComponent as CalendarSvg} from "../assets/fonts/feather/calendar.svg";
import {ReactComponent as ForkSvg} from "../assets/icons/fork.svg";
import {ReactComponent as ReportSvg} from "../assets/icons/report.svg";
import {ReactComponent as PlusCircleSvg} from "../assets/fonts/feather/plus-circle.svg";
import {Link} from "react-router-dom";
import {setTitle} from "../actions/main";
import {useDispatch} from "react-redux";

export default function Settings(props) {

    const dispatch = useDispatch()

    const links = [
        [
            {
                label: 'Журнал посещения',
                icon: <JournalSvg/>,
                link: '/attendance'
            }
        ],
        [

            {
                label: 'Дополнительные занятия',
                icon: <PlusSvg/>,
                link: '/additionals'
            },
            {
                label: 'Индивидуальные занятия',
                icon: <PlusSvg/>,
                link: '/individuals'
            },
        ],
        [
            {
                icon: <ReportSvg/>,
                label: 'Отчеты психолога',
                link: '/psychologist',
                disabled: false
            },
            {
                icon: <ReportSvg/>,
                label: 'Отчеты логопеда',
                link: '/logopedist',
                disabled: false
            },
            {
                icon: <ForkSvg/>,
                label: 'Меню на неделю',
                link: '/food',
                disabled: false
            },
            {
                icon: <CalendarSvg/>,
                label: 'Расписание',
                link: '/timeline',
                disabled: false
            },
            {
                icon: <CalendarSvg/>,
                label: 'Календарь праздников',
                link: '#',
                disabled: true
            },
        ],
        [
            {
                label: 'Задать вопрос',
                icon: <HelpSvg/>,
                link: '/feedback'
            },
            {
                label: 'Выход',
                icon: <LogoutSvg/>,
                link: '/exit'
            },
        ]
    ]

    dispatch(setTitle('Навигация'))


    return (
        links.map((group) => {
            return (
                <nav className={'menu block'}>

                    {/*<h2>Discovery Clients</h2>*/}

                    <ul>

                        {group.map((el) => {
                            return (
                                <div className={'additional-item'}>
                                    <Link to={el.link} className={'additional-item__link ' + (el.disabled === true ? 'disabled' : '')}>
                                        <i>{el.icon}</i>
                                        <span className="additional-item__name">{el.label}</span>

                                    </Link>
                                </div>
                            )
                        })}
                    </ul>
                </nav>
            );
        })
    )

}