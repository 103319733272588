import Loader from "../components/Loader";
import React, {useState} from "react";
import {UsersService} from "../services/UsersService";
import {DepartmentService} from "../services/DepartmentService";
import withRouter from '../components/WithRouter'

class UserEditAccess extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            auths: []
        }
        this.group = '';
    }

    componentDidMount() {
        let cleanupFunction = false;
        const fetchData = async () => {
            try {
                UsersService.GetAuths().then((response) => {
                    this.setState({auths: response})
                })
            } catch (e) {

            }
        }
        fetchData();

        // функция очистки useEffect
        return () => cleanupFunction = true;
    }



    render() {

        return (
            <form className={'theme-form'}>


                {this.state.auths.length ? this.state.auths.map((el, k) => {

                    let title = '';
                    if (this.group !== el.group) {
                        title = <strong>{el.group}</strong>
                        this.group = el.group
                    }


                    return (
                        <>
                            {title}
                            <div className="form-check checkbox mb-0">
                                <input className="form-check-input"
                                       type="checkbox" id={'auth-' + k}/>
                                <label className="form-check-label"
                                       htmlFor={"auth-" + k}>{el.description}</label>
                            </div>
                        </>
                    )
                }) : <Loader/>}

                <br/>
                <br/>
                <button type={'submit'} className={'btn btn-primary'}>Сохранить</button>
            </form>
        )
    }

}

export default withRouter(UserEditAccess)