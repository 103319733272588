import React, {useState} from "react";

import {Link} from "react-router-dom";
import {setTitle} from "../actions/main";
import {useDispatch, useSelector} from "react-redux";
import {sendRequest} from "../services/Sender";
import {userService} from "../services/UserService";
import FormGroup from "../components/FormGroup";
import PhoneInput from "react-phone-input-2";

export default function Feedback(props) {

    const dispatch = useDispatch()
    const [formSended, setFormSended] = useState(false)

    const userData = useSelector(function (state) {
        return state.auth.userData
    });


    const [phone, setPhone] = useState(false)
    const [text, setText] = useState('')



    dispatch(setTitle('Задать вопрос'))


    const handleSubmit = (e) => {
        e.preventDefault()
        sendRequest('feedback', 'GET', {
            phone: phone,
            text: text,
            fio: userData.name,
            email: userData.email,
            department_id: userData.department_id

        }).then(response => {
            setFormSended(true)
        })
    }

    return (
        <>
            <div className={'block'}>
                {!formSended ?
                <form className={"theme-form"} onSubmit={handleSubmit}>
                    <FormGroup label={"Ваш номер телефона"}>
                        <PhoneInput specialLabel={false} value={phone} onChange={(e) => setPhone(e)} country={'ru'} inputClass={'form-control'}
                                     inputProps={{required: true}}/>
                    </FormGroup>
                    <FormGroup label={"Текст сообщения"}>
                        <textarea className={'w-100'} rows={10} value={text} onChange={(e) => setText(e.target.value)}></textarea>
                    </FormGroup>
                    <div className="form-group">
                        <button onClick={handleSubmit} className={"btn btn-primary btn-block w-100" } type="submit">Задать вопрос</button>
                    </div>
                </form>
                    : <><p>Ваше сообщение отправлено в управляющую компанию.</p><p>Ближайшее время мы свяжемся с Вами!</p></> }
            </div>
        </>
    )

}