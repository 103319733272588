
import React from "react";

class Tablist extends React.Component {

    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            tabs: props.tabs,
            activeTab: props.activeTab
        }
    }

    render() {
        return (
            <ul className={'nav nav-pills'} role={'tablist'}>
                {this.state.tabs.map((tab) => {
                    return (
                        <li className={'nav-item '}>
                            <a href="#" onClick={() => this.props.handleClick(tab.id)} className={'nav-link ' + (this.state.activeTab === tab.id ? 'active' : '')}>{tab.title}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

}

export default Tablist