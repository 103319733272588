import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";

export default function HomePage(props) {

    let navigation = useNavigate();



    useEffect(() => {
        navigation('/about')
    })

    return (
        <div></div>
    )
}